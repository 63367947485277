import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetCartQuery, useUpdateCartQuantityMutation } from '../../redux-services/apis/cartApi';
import CartProduct from './CartProduct';
import CartSummery from './CartSummery';
import { getSelectedShippingRate } from '../../redux-services/slices/cartSlice';
import { useSelector } from 'react-redux';

function Cart() {
  const selectedShippingRate = useSelector(getSelectedShippingRate);
  const [cartData, setCartData] = useState(null);
  const [checkout, setCheckout] = useState(false);
  const [updateCartQuantity, updateCartQuantityResponse] = useUpdateCartQuantityMutation();

  const { isSuccess, data } = useGetCartQuery(
    selectedShippingRate?.totalCharges
  );

  useEffect(() => {
    if (isSuccess) {
      setCartData(data.response);
    }
  }, [data]);

  const handleClearCart = (cartId, dataOption) => {
    updateCartQuantity({
      cartId: cartId,
      dataOption: dataOption,
    })
  }

  return (
    <>
      <section className="checkoutpage">
        <Container>
          <Row>
            {cartData?.count ? (
              <>
                <Col md="12">
                  <h2>YOUR CART({cartData?.count})</h2>
                </Col>
                <Col md="12" lg="8">
                  <div className="checkoutOuter">
                    {cartData?.products &&
                      cartData.products.map((cartProductData) => (
                        <CartProduct
                          key={cartProductData._id}
                          product={cartProductData}
                          cartId={cartData.cart_id}
                          cartDetail={cartData}
                        />
                      ))}
                  </div>
                </Col>

                <Col md="12" lg="4">
                  <CartSummery cartData={cartData} checkout={checkout} setCheckout={setCheckout}/>
                </Col>
                <Col md="12">
                  <div className='cart-bottom-buttons'>
                    <button className='btn btn-secondary' onClick={(e) => handleClearCart(cartData.cart_id, 'clearCart')}>Clear Cart</button>
                    {/* <Button onClick={(e) => setCheckout(true)}>
                      Proceed to Checkout
                    </Button> */}
                  </div>
                </Col>
              </>
            ) : null}

            {cartData?.count == 0 ? (
              <Col md="12">
                <div className="empty-cart">
                  <h3>Your cart is empty</h3>
                  <Link className="btn btn-primary" to="/shafts/category/all">
                    Continue Shopping
                  </Link>
                </div>
              </Col>
            ) : null}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Cart;
