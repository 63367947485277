import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProductType } from '../../../constants';
import {
  getFilteredProducts,
  getGearFilteredProducts,
} from '../../../redux-services/slices/productDataSlice';
import WishListMark from '../WishListMark';

const Product = ({ type }) => {
  const getProducts = useSelector(getFilteredProducts);
  const getGearProducts = useSelector(getGearFilteredProducts);
  const productList = type === ProductType ? getGearProducts : getProducts;
  const baseUrl = type === ProductType ? 'gear' : 'shafts';

  return (
    <Col md="8" lg="9">
      <ul className="itemList">
        {productList.map((product) => (
          <li key={product._id}>
            <div className="mainOuter-list">
              <div className="mainOuter">
                <div className="ItemImgOuter">
                  <Link to={`/${baseUrl}/${product.slug}`}>
                    <img
                      src={product.thumbnail ?? '/images/productdummy.png'}
                      alt={product.product_title}
                      className="img-fluid"
                      onError={() => (this.src = '/images/productdummy.png')}
                    />
                  </Link>
                </div>
                <div className="itemContentOuter">
                  <WishListMark productId={product._id} />
                  <h4>{product.sub_title}</h4>
                  <p>{product.sub_heading}</p>
                  <h5>
                    <span style={{ textTransform: "capitalize" }}>
                      <Link to={`/${baseUrl}/${product.slug}`}>
                        {product.product_title} - {product.categorySlug}
                      </Link>
                    </span>
                  </h5>
                  <span>
                    From{' '}
                    {/* {parseInt(
                      product.discounted_price?.$numberDecimal
                        ? product.discounted_price?.$numberDecimal
                        : product.sale_price > 0
                        ? product.sale_price
                        : product.msrp_price
                    ) < parseInt(product.msrp_price).toFixed(2) ? (
                      <>
                        <span style={{ textDecoration: 'line-through' }}>
                          $ {product.msrp_price}{' '}
                        </span>{' '}
                        -{' '}
                      </>
                    ) : null} */}
                    <span>
                      $
                      {product.discounted_price?.$numberDecimal
                        ? product.discounted_price?.$numberDecimal
                        : ((Math.min(
                          ...product?.sale_price?.filter(
                            (number) => number > 0
                          )
                        ) !== Infinity || 0) && Math.min(
                          ...product?.sale_price?.filter(
                            (number) => number > 0
                          )
                        ) < Math.min(
                          ...product?.msrp_price?.filter(
                            (number) => number > 0
                          )
                        ))
                          ? Math.min(
                            ...product?.sale_price?.filter(
                              (number) => number > 0
                            )
                          )
                          : Math.min(
                            ...product?.msrp_price?.filter(
                              (number) => number > 0
                            )
                          )}
                    </span>{' '}
                  </span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default Product;
